<template>
  <div>
    <BaseModal
      :showing="visible"
      @close="onClose"
      :showClose="visible"
      :backgroundClose="true"
      size="max-w-3xl"
    >
      <div class="space-y-6">
        <div class="flex items-center justify-between">
          <div>
            <h3 class="text-lg font-bold leading-6 text-gray-900">
              Detail Pengiriman
            </h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              Data Pengiriman Barang dari Supplier
            </p>
          </div>
          <div>
            <img src="@/assets/images/logo.png" alt="" class="w-32" />
          </div>
        </div>
        <div class="border-t border-gray-200 pt-6" v-if="visible">
          <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
            <slot name="attribute" :stock-movement="getStockMovement">
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">
                  Nomor Pengiriman
                </dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ getStockMovement.data.attributes.code }}
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">Nomor Faktur</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ getStockMovement.data.attributes.order_code }}
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">Supplier</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ getStockMovement.data.attributes.origin_office_name }}
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">Tanggal</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ getStockMovement.data.attributes.updatedAt | formatDate }}
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">Total Barang</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{
                    Object.keys(
                      getStockMovement.data.attributes.product_summaries
                    ).length
                  }}
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">Status</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  <base-badge
                    :color="
                      getStockMovement.data.attributes.is_shipped
                        ? 'green'
                        : 'red'
                    "
                  >
                    {{
                      getStockMovement.data.attributes.is_shipped
                        ? 'Dikirim'
                        : 'Belum Dikirim'
                    }}</base-badge
                  >
                </dd>
              </div>
            </slot>
            <slot name="attribute" :stock-movement="getStockMovement">
              <div class="sm:col-span-6">
                <dt class="text-sm font-bold text-gray-700">Barang</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  <Datatable :paginated="false" :footer="false">
                    <template v-slot:thead>
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Kode
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Nama
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Jumlah
                          </th>
                          <th
                            scope="col"
                            class="w-1/12"
                          >
                          </th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:tbody>
                      <tbody>
                        <template v-for="(product, index) in getStockMovement.data.attributes.packets[0].products">
                          <tr
                            :key="index"
                            class="bg-white hover:bg-green-100"
                            @click="setSelectedMovementDetail(product)"
                          >
                            <td
                              class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                            >
                              {{ product.product_code }}
                            </td>
                            <td
                              class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                            >
                              {{ product.product_name }}
                            </td>
                            <td
                              class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                            >
                              {{ product.shipped_product_qty | toCurrency }}
                            </td>
                            <td
                              class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="h-4 w-4 transition-all"
                                :class="
                                  product.uuid === selectedMovementDetail.uuid
                                    ? 'rotate-180'
                                    : null
                                "
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                />
                              </svg>
                            </td>
                          </tr>
                          <!-- detail batch -->
                          <template v-if="selectedMovementDetail.uuid === product.uuid">
                            <tr v-for="(batch, batchIndex) in  productBatchesKeyByProduct[product.product_code]" class="bg-gray-50" :key="`${index}-batch-${batchIndex}`">
                              <td
                                class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                              >
                                {{ batch.attributes.no_batch }}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                              >
                                {{ batch.attributes.expires_at | formatDate }}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                                coslpan=2
                              >
                                {{ batch.attributes.product_qty | toCurrency }}
                              </td>
                              <td></td>
                            </tr>
                          </template>
                        </template>
                      </tbody>
                    </template>
                  </Datatable>
                </dd>
              </div>
            </slot>
          </dl>
        </div>
        <div class="flex justify-end gap-x-2">
          <button
            v-if="!getStockMovement.data.attributes.is_shipped"
            @click="onSend"
            type="button"
            class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
          >
            Kirim
          </button>
          <button
            v-on:click="onClose"
            type="button"
            class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
          >
            Tutup
          </button>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { getRelationships } from '@/services/utils.service';
import BaseModal from '@/components/base/BaseModal.vue';
import BaseBadge from '@/components/base/BaseBadge.vue';
import Datatable from '@/components/base/Datatable.vue';

export default {
  name: 'SupplierPengirimanModal',
  components: {
    BaseModal,
    BaseBadge,
    Datatable
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'refresh'],
  data() {
    return {
      selectedMovementDetail: {
        uuid: null
      }
    };
  },
  computed: {
    ...mapGetters({
      getStockMovement: 'stock_movements/getStockMovement',
    }),
    productBatchesKeyByProduct() {
      return this.getIncludedByType(this.getStockMovement, 'product-batches').reduce((prev, current) => {
        if (Object.hasOwn(prev, current.attributes.product_code)) {
          return {
            ...prev,
            [current.attributes.product_code]: [...prev[current.attributes.product_code], current]
          }
        } else {
          return {
            ...prev,
            [current.attributes.product_code]: [current]
          }
        }
      }, {})
    }
  },
  methods: {
    getRelationships,
    ...mapActions({
      sendStockMovement: 'stock_movements/sendStockMovement',
      createAlert: 'alert/createAlert',
    }),
    onClose() {
      this.$emit('close');
    },
    async onSend() {
      const res = await this.sendStockMovement({
        data: {
          type: 'stock-movements',
          id: this.getStockMovement.data.id,
          attributes: {
            taken_by: 'Superadmin',
          },
        },
      });

      if (res) {
        this.createAlert({
          data: res ? 'Pengiriman Berhasil' : 'Pengiriman Gagal',
          status: res ? 'success' : 'error',
        });
      }

      this.$emit('refresh');
    },
    setSelectedMovementDetail(product) {
      if (this.selectedMovementDetail.uuid === product.uuid) {
        this.selectedMovementDetail.uuid = null
      } else {
        this.selectedMovementDetail.uuid = product.uuid
      }
    }
  },
};
</script>
